import { Component, OnInit } from '@angular/core';
import { CloudinaryService } from '../services/cloudinary.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit {
  visible=false;
  linkmsn='https://m.me/203977802962086';
  linkmsn2='https://m.me/101300798385717';
  imagefacebook:any;

  constructor(private cloudinaryService: CloudinaryService) { }

  ngOnInit(): void {
    this.imagefacebook = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1713559345/production/plataforma-toyota/web/assets/icons/comunications/botones_chat-02.svg', {
      width: 50,
      height: 50,
      fetch_format: 'auto',
      quality: 'auto'
    });
  }
  
  onmousemove() {
    this.visible = true;
    setTimeout(() => {
      this.visible = false;
    }, 5000);
    
  }

  open() {
      this.visible = true;
  }

  close() {
      this.visible = false;
  }

}
