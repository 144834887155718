<div class="footer" *ngIf="web">
    <div class="container">
        <div class="row">
            <div class="col-8 col-md-10 col-xl-10">
                <div class="row">
                    <div class="col-6 col-md-4 col-xl-4 foot-info-section">
                        <a routerLink="/contacto" class="foot-link">Contáctanos</a>
                    </div>
                    <div class="col-6 col-md-4 col-xl-4 foot-info-section">
                        <a routerLink="/quienes-somos" class="foot-link">Sobre nosotros</a>
                    </div>
                    <div class="col-6 col-md-4 col-xl-4 foot-info-section">
                        <a href="https://noticias.toyosa.com/" class="foot-link">Noticias</a>
                    </div>
                    
                </div>
            </div>
            <div class="col-4 col-md-2 col-xl-2">
                <div class="row foot-rs">
                    <div class="col-6 col-md-3 col-xl-3">
                        <a href="https://www.facebook.com/ToyotaBoliviaoficial" target="_blanck" class="rrss-foot-link">
                            
                        <img [src]="imagefacebook"  width="25" height="25" alt="facebook Toyosa" loading="lazy" class="rrss-foot-img"/>
                        </a>
                    </div>
                    <div class="col-6 col-md-3 col-xl-3">
                        <a href="https://www.instagram.com/toyota_bolivia/" target="_blanck" class="rrss-foot-link">
                            <img [src]="imageinstagram" width="25" height="25" alt="Instagram Toyosa" loading="lazy" class="rrss-foot-img"/>
                        </a>
                    </div>                    
                    <div class="col-12 col-md-6 col-xl-6">
                        <a href="tel:591800175200" target="_blanck" class="is-linnk">
                            <img [src]="contactcenter" width="86" height="22" alt="Contact Center Toyosa" loading="lazy" class="rrss-foot-imgcc"/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copy">
        <div class="container">
            <div class="row">
                <div class="col-6 col-md-2 col-xl-2">
                    <a routerLink="/condiciones-uso" class="copy-link">Condiciones de uso</a>
                </div>
                <div class="col-6 col-md-2 col-xl-2">
                    <a routerLink="/politicas-privacidad" class="copy-link">Política de privacidad</a>
                </div>
                <div class="col-12 col-md-8 col-xl-8 copy-firma">
                    © 2021 Toyosa copyright. all rights reserved
                </div>
            </div>
        </div>
    </div>
</div>
<app-chat></app-chat>