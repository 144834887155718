// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  base_url: 'https://ws-plataforma-toyota.toyosa-test.com/api/v1',
  base_url_servidor: 'https://ws-toyosa.toyosa-test.com',
  base_url_sucursales: 'https://ws-toyosa.toyosa-test.com/api/v2',
  base_url_v2: 'https://ws-plataforma-toyota.toyosa-test.com/api/v2',
  cardinal_url:'https://songbirdstag.cardinalcommerce.com/cardinalcruise/v1/songbird.js',
  cyberSourceID : '1snn5n9w'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
