import { NgModule } from '@angular/core';
import {Routes, RouterModule, PreloadAllModules} from '@angular/router';
import { VehiculosToyotaComponent } from './vehiculos-toyota/vehiculos-toyota.component';
import { TakataComponent } from './campanas/takata/takata.component';
import { Fexco2024Component } from './campanas/fexco2024/fexco2024.component';

const routes: Routes = [
  { path: '',loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
  { path: 'home',loadChildren: () => import('./home/home.module').then(m => m.HomeModule)},
  { path: 'loader',loadChildren: () => import('./loader/loader.module').then(m => m.LoaderModule)},
  { path: 'servicio-tecnico', loadChildren: () => import('./servicio-tecnico/servicio-tecnico.module').then(m => m.ServicioTecnicoModule) },
  { path: 'usuario', loadChildren: () => import('./usuario/usuario.module').then(m => m.UsuarioModule) },
  { path: 'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule) },
  { path: 'quienes-somos', loadChildren: () => import('./quienes-somos/quienes-somos.module').then(m => m.QuienesSomosModule) },
  { path: 'contactanos', loadChildren: () => import('./contacto/contacto.module').then(m => m.ContactoModule) },
  { path: 'condiciones-uso', loadChildren: () => import('./condiciones-uso/condiciones-uso.module').then(m => m.CondicionesUsoModule) },
  { path: 'politicas-privacidad', loadChildren: () => import('./politicas-privacidad/politicas-privacidad.module').then(m => m.PoliticasPrivacidadModule) },
  { path: 'tienda-virtual', loadChildren: () => import('./ecommerce/ecommerce.module').then(m => m.EcommerceModule) },
  { path: 'llamado-a-revision', loadChildren: () => import('./campanas/campanas.module').then(m => m.CampanasModule) },
  { path: 'ofertas', loadChildren: () => import('./campanas/campanas.module').then(m => m.CampanasModule) },
  /*{ path: 'tienda-virtual/oferta-filtros-de-combustible', loadChildren: () => import('./campanas/campanas.module').then(m => m.CampanasModule) },*/
  { path: 'contacto', loadChildren: () => import('./contacto/contacto.module').then(m => m.ContactoModule) },
  { path: 'franquicia', loadChildren: () => import('./sucursales/sucursales.module').then(m => m.SucursalesModule) },
  //{ path: 'test-drive', loadChildren: () => import('./test-drive/test-drive.module').then(m => m.TestDriveModule) },
  //{ path: ':marca/:modelo', loadChildren: () => import('./pip/pip.module').then(m => m.PipModule)},
  //{ path: 'seminuevos', loadChildren: () => import('./seminuevos/seminuevos.module').then(m => m.SeminuevosModule) },
  { path: 'terminos-y-condiciones', loadChildren: () => import('./terminos-y-condiciones/terminos-y-condiciones.module').then(m => m.TerminosYCondicionesModule) },
  { path: 'campanas', loadChildren: () => import('./campanas/campanas.module').then(m => m.CampanasModule) },
  //{ path: 'fexco2024', component: Fexco2024Component },
  { path: 'taller-movil', loadChildren: () => import('./taller-movil/taller-movil.module').then(m => m.TallerMovilModule) },
  { path: 'aplicacion', loadChildren: () => import('./aplicacion/aplicacion.module').then(m => m.AplicacionModule) },
  { path: 'error404', loadChildren: () => import('./error404/error404.module').then(m => m.Error404Module) },
  { path: 'ecommerce/pago-tienda', loadChildren: () => import('./ecommerce/pago-tienda/pago-tienda.module').then(m => m.PagoTiendaModule) },
  //radio de los vehículos...no eliminar url porfa
  { path: 'vehiculos-toyota', component: VehiculosToyotaComponent},
  { path: 'takata', component: TakataComponent},
  { path: 'talleres', loadChildren: () => import('./talleres/talleres.module').then(m => m.TalleresModule) },
  { path: 'chat', loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule) },
  { path: 'concursodreamcar18', loadChildren: () => import('./sitio-en-construccion/sitio-en-construccion.module').then(m => m.SitioEnConstruccionModule) },
  { path: '**', redirectTo: 'error404' }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
