import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ImageloadService } from '../services/imageload.service';
import { HttpClient } from '@angular/common/http';
import { CloudinaryService } from '../services/cloudinary.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  /* web view */
  web=true;
  imagefacebook:any;
  imageinstagram:any;
  contactcenter:any;
  
  constructor(private cargarimagen: ImageloadService, private http: HttpClient, private cloudinaryService: CloudinaryService) {
   }

  ngOnInit(): void {
    const pathname = window.location.pathname;
        const elHeader: HTMLElement | null = document.getElementById('header-menu-desktop');

        let patheCommerce = pathname.startsWith("/tienda-virtual/");

        switch (patheCommerce) {
          case true:
            const valores = window.location.search;
            console.log(valores);
            if(valores) {
              this.web=false;
              
            } else {
              this.web=true;
              if (elHeader) {
                elHeader.classList.add('back-gray');
              }
            }
            break;
          case false:
        }
    
        this.loadImage();
    
  }

  

  loadImage() {

    this.imagefacebook = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1713563352/production/plataforma-toyota/web/assets/icons/comunications/facebook.png', {
      width: 25,
      height: 25,
      fetch_format: 'auto',
      quality: 'auto'
    });

    this.imageinstagram = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1713563605/production/plataforma-toyota/web/assets/icons/comunications/instagram.png', {
      width: 25,
      height: 25,
      fetch_format: 'auto',
      quality: 'auto'
    });

    this.contactcenter = this.cloudinaryService.getImageUrl('https://res.cloudinary.com/toyosa-sa/image/upload/v1713563677/production/plataforma-toyota/web/assets/icons/comunications/icono_contact_footer.png', {
      width: 86,
      height: 22,
      fetch_format: 'auto',
      quality: 'auto'
    });
    
  }

}
